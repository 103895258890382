.ProjectContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

@media screen and (min-width: 768px) {
  .ProjectWrapper {
    margin: 1rem 0;
    width: calc(50% - 1rem);
  }
}
