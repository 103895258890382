.Brand {
  color: var(--accent-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem auto;
  max-width: 18.75rem;
  text-align: center;
  text-transform: uppercase;
}

.Brand_Super {
  margin: 0;
}

.Brand_Logo {
  margin: 1rem;
  width: 18.75rem;
}

.Brand_Border {
  border: 1px solid var(--accent-color);
  margin: 1.375rem auto;
  width: 6.25rem;
}

.MegaMenu.active .Brand {
  margin-top: 3rem;
}

@media screen and (min-width: 768px) {
  .Brand {
    max-width: 23.4375rem;
  }

  .Brand_Logo {
    width: 23.4375rem;
  }
}
