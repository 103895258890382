:root {
  --accent-color: rgba(176, 163, 148, 1);
  --body: rgba(255, 255, 255, 1);
  --border: rgba(29, 29, 29, 1);
  --font-color: rgba(39, 39, 39, 1);
}

html, body, .Root {
  height: 100%;
}

body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: 'Roboto', 'Helvetica Neue', -apple-system, sans-serif;
  margin: 0;
  padding: 0 0.3125rem;
}

h1 {
  margin: 1.375rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  width: 1px;
}

.Image {
  max-width: 100%;
  height: auto;
}

.Heading {
  line-height: 1.4;
}

@media screen and (min-width: 768px) {
  body {
    padding: 0;
  }
}
