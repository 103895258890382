.PhoneNumber {
  display: inline;
  margin: 0;
  padding: 0;
  text-transform: uppercase;
}

.Brand .PhoneNumber {
  font-size: 0.75rem;
}

.PhoneNumber_Link:link,
.PhoneNumber_Link:visited,
.PhoneNumber_Link:hover,
.PhoneNumber_Link:active {
  color: var(--accent-color);
  text-decoration: none;
}
