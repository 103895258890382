.PageWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.Footer {
  margin-top: auto;
  margin-bottom: 1rem;
}

@media screen and (min-width: 768px) {
  .PageWrapper {
    background-color: white;
    margin: 0 auto;
    width: 75%;
  }
}
